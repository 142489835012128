import React from 'react'
import { Accordion,Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { AccordionItemFull } from "~components/Accordion";
import { Link } from '~components';
import TabList from './Component/Tab'
import Faq from './style'
import Cta from './style'
export default function FaqSection(){
return(
<Faq>
  <Container>
    <Row className="justify-content-center">
      <Faq.Box>
        <Faq.Title as="h3" mb="61px">
          Frequently Asked Questions
        </Faq.Title>
      </Faq.Box>
    </Row>
    <Faq.Body>
    <Faq.TabWrapper as={Tab.Container}
          id="left-tabs-example"
          defaultActiveKey="first">
      <Row className="justify-content-center">
        <Col className="col-xl-10">
          <Row className="justify-content-center justify-md-content-start">
            {/* <div className="col-xl-3 col-lg-4 col-md-5 col-xs-8 mb-6 mb-lg-0">
                <TabList as={Nav}>
                <Nav.Link
                  eventKey="first"
                  className="nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                >
                  General
                  
                </Nav.Link>
                <Nav.Link
                  eventKey="second"
                  className="col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                >
                   Accounts
                </Nav.Link>
                <Nav.Link
                  eventKey="third"
                  className="widget widget--feature nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                >
                  Sales
                </Nav.Link>
                <Nav.Link
                  eventKey="fourth"
                  className="widget widget--feature nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                >
                  Support
                </Nav.Link>
                <Nav.Link
                  eventKey="fifth"
                  className="widget widget--feature nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                >
                  License
                </Nav.Link>
                <Nav.Link
                  eventKey="sixth"
                  className="widget widget--feature nav-item col-lg-12 col-md-4 col-xs-6 col-8 me-md-0 me-lg-0"
                >
                  Refund
                </Nav.Link>
                </TabList>
            </div> */}
            <Col className="col-xl-9 col-lg-8 col-md-11">
              

            <Tab.Content className="tab-content tab-content--feature">
              <Tab.Pane eventKey="first">
                  <Faq.Accordion>
                  <Accordion defaultActiveKey="1a">
                  <AccordionItemFull question="What is a Goitre?" answer="Goitre is an enlargement of the thyroid Gland, which is situated in front of the neck." accKey ="1a"/>
                  <AccordionItemFull question="	What are the common problems encountered in the Thyroid gland?" answer="The gland may function more (Hyper) or less (Hypo) than necessary. It may simply become bigger and bigger. It may turn cancerous" accKey ="2a"/>
                  <AccordionItemFull question="Can it affect children?" answer="Yes, In fact, it can affect all age groups.Exact cause may vary. This has to be investigated." accKey ="3a"/>
                  <AccordionItemFull question="Can I have a normal hormone level and yet have a goitre?" answer="Yes, it is possible. A goitre with normal thyroid hormone level will not respond to medical management (thyroxin supplementation). Cancer of thyroid should be ruled out in all these cases." accKey ="4a"/>
                  
                  <AccordionItemFull question="	Why should I undergo the test called FNAC?" answer="Only FNAC will answer three important questions. 1. Whether your Goitre will respond to medical management or not? 2. Whether there is cancer in your thyroid or not? 3. If you need surgery, what type of surgery to be performed? Hence FNAC is a must." accKey ="5a"/>
                  <AccordionItemFull question="	Why should I undergo ultrasonogram of neck?" answer="Only large nodules in the thyroid are palpable. Small nodules may not be palpable. Sometimes patient can have small nodules in one lobe of thyroid in addition to the large nodule on the other lobe. This can be detected only by ultrasonogram. Presence of small nodules on the opposite lobe alters the line of surgical management. In addition ultrsonogram can detect small enlarged lymph nodes in the Neck. In such cases we can do Ultrasound guided FNAC of the lymph node to rule out Cancer. Hence Ultrasonogram is a must for all patients with goitre." accKey ="6a"/>
                  <AccordionItemFull question="	Is thyroid surgery a major operation?" answer="Yes. It is a major operation. It needs general anaesthesia. Type of surgery depends on the type of problem. One can lead a perfectly normal life after thyroid surgery even though some may require hormone replacement." accKey ="7a"/>
                  <AccordionItemFull question="	If I need surgery what should I do?" answer="	 If you need surgery you will have to undergo complete preoperative investigations. Our physician & Anaesthesiologist will examine you with the investigation report and give their opinion. In case if you have any medical problem, they will treat it before submitting you for surgical correction." accKey ="8a"/>
                  
                  <AccordionItemFull question="How long should I be hospitalised?" answer="Usually less than five days." accKey ="9a"/>
                  <AccordionItemFull question="Is surgery the only remedy for goitre?" answer="	No, some goitres will regress with thyroxine supplementation." accKey ="10a"/>
                  <AccordionItemFull question="Can Iodised salt solve my problem?" answer="It depends on the cause of the goitre." accKey ="11a"/>
                  <AccordionItemFull question="Autoimmune Thyroiditis - How to Diagnose and How Long to Treat?" answer="Majority of patients in the age group of 12 to 25 years attending the out patient clinic of the Department of Endocrine Surgery, Government General Hospital,Chennai ( a tertiary care Hospital in South India) are suffering from Thyroiditis. Most of these patients seek medical aid when they develop a diffuse goiter. Some of them presented with palpitation and tremors without thyromegaly but the majority of patients presented with diffuse goiter in Hypothyroid state.

Investigations done to confirm the diagnosis: (1) FT3,FT4,TSH (2)Thyroid antibodies: Antimicrosomal antibody (TPO) and Anti thyroglobulin antibody(ATG) and (3) Fine Needle Aspiration Cytology.

Thyroiditis is confirmed by positive Antimicrosomal Antibody titre (AMA). All Thyroiditis patients with diffuse goitre had elevated serum TSH with decreased FT3 & FT4 confirming Hypothyroid status.Hence,they were all treated with thryoxine 50 to 100mcg OD. Patients who presented with palpitation and tremors had elevated FT3 & FT4 associated with decreased TSH confirming that they were in the toxic phase of thyroiditis.Hence, those patients were treated with beta blocker propranolol.

AntiThyroglobulin antibody titre was used to differentiate Toxic phase of thyroiditis from Graves' disease. Toxic phase of thyroiditis is due to increased liberation of stored hormone in to the system, due to follicular damage and not due to increased production of thyroid hormone.Patients in toxic phase of thyroiditis developed severe hypothyroidism when treated with antithyroid drug carbimazole for a few weeks.

FNAC is a very useful investigation to confirm Thyroiditis. Cytology revealed Lymphocytic infiltration in all patients with thyroidtis.

AMA(TPO) titre became negative in most of our patients over a period of 12 to 18 months. Hence the treatment was given for the same period. Negative AMA(TPO) titre is an indication to withdraw the medical treatment." accKey ="12a"/>
                  
                  </Accordion>
                  </Faq.Accordion>
              </Tab.Pane>
              {/* <Tab.Pane eventKey="second">
                  <Faq.Accordion>
                  <Accordion defaultActiveKey="1b">
                  <AccordionItemFull question="Where do I find my fastland product key?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="1b"/>
                  <AccordionItemFull question="Does it work with WordPress?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="2b"/>
                  <AccordionItemFull question=" What happens after I download?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="3b"/>
                  <AccordionItemFull question="Will you provide support?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="4b"/>
                  </Accordion>
                  </Faq.Accordion>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                  <Faq.Accordion>
                  <Accordion defaultActiveKey="1c">
                  <AccordionItemFull question="I am having trouble downloading, what else should I do?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="1c"/>
                  <AccordionItemFull question="How long does it take to download?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="2c"/>
                  <AccordionItemFull question="Do I get free updates?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="3c"/>
                  <AccordionItemFull question="Will you provide support?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="4c"/>
                  </Accordion>
                  </Faq.Accordion>
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                  <Faq.Accordion>
                  <Accordion defaultActiveKey="1d">
                  <AccordionItemFull question="Will you provide support?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="1d"/>
                  <AccordionItemFull question="Does it work with WordPress?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="2d"/>
                  <AccordionItemFull question="Do I get free updates?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="3d"/>
                  <AccordionItemFull question="Can I use Fastland for my clients?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="4d"/>
                  </Accordion>
                  </Faq.Accordion>
              </Tab.Pane>
              <Tab.Pane eventKey="fifth">
                  <Faq.Accordion>
                  <Accordion defaultActiveKey="1e">
                  <AccordionItemFull question="Where do I find my fastland license key?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="1e"/>
                  <AccordionItemFull question="How do I activate my fastland?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="2e"/>
                  <AccordionItemFull question="Can I purchase backup file?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="3e"/>
                  <AccordionItemFull question="Will you provide support?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="4e"/>
                  </Accordion>
                  </Faq.Accordion>
              </Tab.Pane>
              <Tab.Pane eventKey="sixth">
                  <Faq.Accordion>
                  <Accordion defaultActiveKey="1f">
                  <AccordionItemFull question="How do I request a refund?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="1f"/>
                  <AccordionItemFull question="My refund request was denied. Can I contest this decision?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="2f"/>
                  <AccordionItemFull question="Do I get free updates?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="3f"/>
                  <AccordionItemFull question="Will you provide support?" answer="With lots of unique blocks, you can easily build a page without coding. Build your next landing page. Integer ut Oberyn massa. Sed feugiat vitae turpis a porta. Aliquam sagittis interdum Melisandre." accKey ="4f"/>
                  </Accordion>
                  </Faq.Accordion>
              </Tab.Pane> */}
            </Tab.Content>
            <Faq.ButtonGroup mt="50px">
            <p>Haven’t got your answer?<a className="btn-link text-readical-blue ms-2" href="/contact-us">Book a Consultation Session with Dr.MC today.</a>
            </p>
            
            </Faq.ButtonGroup>
            
          </Col>
          </Row>
        </Col>
      </Row>
      </Faq.TabWrapper>
    </Faq.Body>
  </Container>
</Faq>

)
}